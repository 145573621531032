import React, { Fragment } from "react";

import { createCTAView, ViewComponent } from "../_lib/view";

import List from "../_lib/components/List";

const CTAConversionTestView: ViewComponent = () => {
	return (
		<Fragment>
			<List />
		</Fragment>
	);
};

export default createCTAView(CTAConversionTestView, {
	title: "Conversion Test",
	internalScroll: true,
	internalPad: true,
});
