import React from "react";

import { LayoutComponent } from "~/model/layout";
import { PrivatePaths } from "~/model/paths";

import { ITabProps } from "~/components/Tab/types";
import TabbedNav from "~/components/TabbebNav";

const Layout: LayoutComponent<never> = ({ location, pageProps: { ctaId } }) => {
	const tabs: ITabProps[] = [
		{
			location,
			title: "Variants",
			path: `/admin/cta-management/${ctaId}/variants` as PrivatePaths,
		},
		{
			location,
			title: "A/B Tests",
			path: `/admin/cta-management/${ctaId}/conversion-tests` as PrivatePaths,
			disabled: true,
		},
	];

	return <TabbedNav tabs={tabs} />;
};

export default Layout;
