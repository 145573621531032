import React, { Fragment, useMemo } from "react";

import { useAuth } from "@api/auth";
import { useCompanyCta } from "@api/ctas";

import { useLocation } from "@hooks/use-location";

import { navigate } from "@utils/navigate";

import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";

import { DataGrid } from "@mui/x-data-grid";

import { PrimaryButton } from "~/components/Button";
import { Redirect } from "~/components/Redirect";
import { Loading } from "~/components/Loading";

import { customStyles } from "./styles";

import { ctaVariantsData } from "@cta-management/data";

interface CtaData {
	data: {
		rows: any[];
		columns: any[];
	};
	loading: boolean;
	setRowLength: (count: number) => void;
	loadNewData: () => void;
	density: string;
}

const useCtaVariantsData = (): CtaData => {
	const location = useLocation();
	const { state } = location;

	const filter = {
		id: state?.ctaId || "",
		groupId: state?.companyGroup || 0,
	};
	const { ctaVariants, ctaDefaultVariant, loading } = useCompanyCta(filter);
	const ctaVariantsWithDefault = [...ctaVariants, ctaDefaultVariant];
	const ctaVariantsAnalytics = useMemo(
		() => ctaVariantsData(ctaVariantsWithDefault),
		[ctaVariants],
	);
	return {
		data: {
			rows: ctaVariantsAnalytics.rows,
			columns: ctaVariantsAnalytics.columns,
		},
		loading: loading,
		setRowLength(count) {
			console.log("setRowLength", count);
		},
		loadNewData() {
			console.log("loadNewData");
		},
		density: "compact",
	};
};

const List = () => {
	const { user } = useAuth();
	const styles = customStyles();

	const { data, loading } = useCtaVariantsData();
	const override = data.rows.find(
		(cta) => cta.ctaId !== "default" && cta.default,
	);

	const location = useLocation();
	const { state } = location;

	const content = useMemo(() => {
		if (!user?.autocorpAdmin) {
			return <Redirect to="/admin/login-history/" />;
		}

		if (!state?.companyGroup || !state?.ctaId || !state?.companyId) {
			<Redirect to="admin/cta-management/dealer" />;
		}

		if (loading) {
			return <Loading />;
		}

		return (
			<Fragment>
				<Box className={styles.topBar}>
					<Box className={styles.ctaDescription}>
						<Typography variant="h4">CTA Variations</Typography>
						<Typography variant="body1">
							Manage variations on this CTA
						</Typography>
					</Box>
					<PrimaryButton
						onClick={() =>
							navigate(
								`/admin/dealer-management/${state?.companyId}/ctas/${state?.ctaId}`,
								{
									savePath: true,
								},
							)
						}
					>
						{!!override ? "Edit" : "Create"} Override
					</PrimaryButton>
				</Box>
				<Box className={styles.contentContainer}>
					<Box className={styles.tableContainer}>
						<Box className={styles.table}>
							<DataGrid
								localeText={{ noRowsLabel: "No Results" }}
								{...data}
							/>
						</Box>
					</Box>
				</Box>
			</Fragment>
		);
	}, [styles, user?.autocorpAdmin, data]);

	return (
		<Box
			display="flex"
			flexDirection="column"
			flexWrap="nowrap"
			height="100%"
		>
			{content}
		</Box>
	);
};

export default List;
