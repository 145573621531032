import { makeStyles } from "@material-ui/core";

export const customStyles = makeStyles((theme) => ({
	contentContainer: {
		display: "flex",
		flexFlow: "row nowrap",
		height: "100%",
		overflow: "hidden",
	},
	tableContainer: {
		display: "flex",
		flexFlow: "column nowrap",
		width: "100%",
		height: "100%",
		overflow: "auto",
	},
	table: {
		minHeight: 350,
		height: "100%",
		userSelect: "none",
	},
	topBar: {
		padding: "0px 24px 12px",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			flexDirection: "column",
			gap: 16,
			alignItems: "flex-start",
		},
	},
	ctaDescription: {
		display: "flex",
		flexDirection: "column",
		alignItems: "start",
		justifyContent: "space-between",
	},
}));
